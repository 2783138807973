<template>
  <div>
    <b-card>
      <div>
        <div class="d-flex align-items-center justify-center pb-1">
          <feather-icon icon="UserIcon" size="16" />
          <h4 class="pl-1" style="padding-top: 8px;">
            {{ $t('general_info') }}
          </h4>
        </div>
        <validation-observer ref="userValidation">
          <b-row>
            <b-col cols="6"
              ><vi-input
                :disabled="isLdapAccount()"
                rules="required"
                v-model="user.email"
                :label="$t('email')"
              ></vi-input
            ></b-col>
            <b-col cols="6">
              <vi-select
                :options="roleOptions"
                v-model="user.roleId"
                :label="$t('role')"
              ></vi-select
            ></b-col>
          </b-row>
          <b-row>
            <b-col cols="6"
              ><vi-input
                rules="required"
                v-model="user.firstName"
                :label="$t('firstname')"
              ></vi-input
            ></b-col>
            <b-col cols="6"
              ><vi-input
                rules="required"
                v-model="user.lastName"
                :label="$t('lastname')"
              ></vi-input
            ></b-col>
          </b-row>
          <b-row>
            <b-col cols="6"
              ><vi-input
                :rules="userId ? '' : 'required'"
                v-model="user.password"
                type="password"
                :label="$t('password')"
              ></vi-input
            ></b-col>
            <b-col cols="6"
              ><vi-input
                v-model="user.repeatPassword"
                :label="$t('repeat-password')"
                type="password"
              ></vi-input
            ></b-col>
          </b-row>
          <b-row>
            <b-col cols="6"
              ><vi-input
                v-model="user.remoteGUIUsername"
                :label="$t('remote_gui_username')"
              ></vi-input
            ></b-col>
            <b-col cols="6"
              ><vi-input
                v-model="user.remoteGUIPassword"
                :label="$t('remote_gui_password')"
              ></vi-input
            ></b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <vi-select
                rules="required"
                :options="companyOptions"
                v-model="user.companyId"
                :label="$t('company')"
              ></vi-select
            ></b-col>
            <b-col cols="6"></b-col>
          </b-row>
        </validation-observer>
        <b-button class="mt-1" @click="confirm" variant="primary">{{
          $t('save')
        }}</b-button>
      </div>
    </b-card>
    <permissions
      actionGet="devices/GET_DEVICES_WITH_ABILITES"
      actionSave="devices/SAVE_DEVICE_USER_ABILITES"
      v-if="$route.params.id"
    ></permissions>
    <!--<remote-gui></remote-gui>-->
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue';
import ViInput from '@/views/components/ViInput.vue';
import Permissions from './Permissions.vue';
import ViSelect from '../../components/ViSelect.vue';
import { ValidationObserver } from 'vee-validate';
import { onMounted, watch, ref } from '@vue/composition-api';
import store from '@/store';
import { dangerToast, successToast } from '@/utils/toast';
import i18n from '@/libs/i18n';
import { useToast } from 'vue-toastification/composition';
import RemoteGui from './RemoteGUI.vue';

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    ViInput,
    BRow,
    BCol,
    BButton,
    Permissions,
    ViSelect,
    ValidationObserver,
    RemoteGui,
  },
  setup(props, context) {
    const toastInstance = useToast();

    const userId = context.root.$route.params.id;

    const userValidation = ref(null);

    const user = ref({
      email: null,
      firstName: null,
      lastName: null,
      password: null,
      repeatPassword: null,
      roleId: 3,
    });

    const userEmail = ref('');

    let roleOptions = [
      { label: 'Uporabnik', code: 3 },
      { label: 'Admin', code: 2 },
      { label: 'Super Admin', code: 1 },
    ];

    if (store.state.auth.userData.role == 'Admin') roleOptions.pop();

    const companyOptions = ref([]);

    onMounted(async () => {
      const response = await store.dispatch('companies/GET_COMPANIES');
      if (response) {
        companyOptions.value = response.data.map(c => {
          return { code: c.id, label: c.name };
        });
      }

      if (userId) {
        const response = await store.dispatch('auth/GET_USER', {
          userId,
        });
        user.value = response.data;
        userEmail.value = user.value.email;

        if (isLdapAccount())
          user.value.email = user.value.email.replace('@vivozite-ldap.si', '');
      }
    });

    const isLdapAccount = () => {
      return userEmail.value?.includes('vivozite-ldap.si');
    };

    const confirm = async () => {
      const validation = await userValidation.value.validate();
      if (userId || validation) {
        if (
          user.value.password &&
          user.value.password != user.value.repeatPassword
        ) {
          dangerToast(
            toastInstance,
            i18n.t('warning'),
            i18n.t('profile.no_matching_password'),
          );
        } else {
          let { password, repeatPassword, ...u } = user.value;
          if (user.value.password) u.password = user.value.password;

          //user.value.repeatPassword = null;
          //user.value.password = null;

          let response = null;
          try {
            const email = isLdapAccount() && userId ? userEmail.value : user.value.email;
            response = await store.dispatch(
              userId ? 'users/UPDATE_USER' : 'users/CREATE_USER',
              {
                userId,
                user: { ...u, email },
              },
            );
            if (response) {
              successToast(
                toastInstance,
                i18n.t('success'),
                userId ? i18n.t('user_updated') : i18n.t('user_created'),
              );
              context.root.$router.push({ name: 'users' });
            } else {
              dangerToast(
                toastInstance,
                i18n.t('failed'),
                userId
                  ? i18n.t('profile.failed')
                  : i18n.t('user_creation_failed'),
              );
            }
          } catch (e) {
            dangerToast(
              toastInstance,
              i18n.t('failed'),
              i18n.t(e.response.data.message),
            );
          }
        }
      }
    };

    return {
      isLdapAccount,
      user,
      userId,
      roleOptions,
      confirm,
      userValidation,
      companyOptions,
    };
  },
};
</script>

<style></style>
